<template>
  <div class="list-wrapper">
    <div class="head_image">
      <img class="_back" @click="$router.go(-1)" src="https://pic.eaworks.cn/chenghua/btn_back.png" alt="">
      <div class="md_box">
        <div class="md_box_content">
          <video ref="videoBox" :src="videoDetail.video_url"
                 @fullscreenchange="scre"
                 @click="playBtn" webkit-playsinline="true"
                 :poster="videoDetail.poster_image"/>

        </div>
        <div class="content_box">
          <img class="content_image" :src="videoDetail.detail_desc" alt=""/>
          <img v-if="videoDetail.merchant_id!=''" src="https://pic.eaworks.cn/chenghua/video_merchant.png" alt=""/>
          <img @click="jumpDetail" class="merchant_image" v-if="videoDetail.merchant_id!=''"
               :src="videoDetail.link_img_url" alt=""/>
        </div>
      </div>
      <div>
        <img style="pointer-events: none" class="bottom_image" src="https://pic.eaworks.cn/chenghua/video_bottom2.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      videoDetail: {},
      shopList: [],
      categoryInfo: {},
      playFlag: false,
      firstFlag: false,
      fullScreen: false
    };
  },

  created() {
    console.log("-> [card.vue] created");
    if (this.$route.query.id) {
      this.ajaxGetVideoDetail(this.$route.query.id);
    }
  },
  mounted() {
    console.log("-> [card.vue] mounted");
  },
  methods: {
    jumpDetail() {
      this.$router.push("/shop/detail?id=" + this.videoDetail.merchant_id);
    },
    async ajaxGetVideoDetail(id) {
      let {data} = await this.$ajax.gateway("/api/api_get_video_detail", {
        "METHOD": "get",
        "id": id
      });
      this.videoDetail = data;
    },
    scre() {
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
        // 视频已经进入全屏模式
        this.$refs.videoBox.play()
        this.fullScreen = true;
        console.log('视频已进入全屏');
      } else {
        this.$refs.videoBox.pause()
        this.fullScreen = false;
        // 视频已经退出全屏模式
        console.log('视频已退出全屏');
      }
    },
    playBtn(e) {
      if (!this.firstFlag) {
        this.$refs.videoBox.addEventListener('pause', () => {
          console.info("pause")
          if (this.fullScreen) {
            this.$refs.videoBox.requestFullscreen()
          }
        });
        this.$refs.videoBox.addEventListener('playing', () => {
          if (!this.fullScreen) {
            this.$refs.videoBox.requestFullscreen()
          }
        });
        this.firstFlag = true;
      }
      if (!this.playFlag) {
        this.$refs.videoBox.play()
        this.$refs.videoBox.requestFullscreen()
      } else {
        this.$refs.videoBox.pause()
        this.$refs.videoBox.requestFullscreen()
      }
    },
  }
};
</script>

<style lang="less" scoped>
.content_box {
  .content_image {
    width: 90%;
  }
  .merchant_image {
    width: 80%;
  }
}

.head_image {
  img {
    width: 100%;
  }
  background-image: url("https://pic.eaworks.cn/chenghua/video_main_bg0513.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
}


.md_box {
  margin: 0 auto;
  text-align: center;
  background-size: 100% auto;

  .md_box_content {
    video {
      margin: 2.5rem 0 0 0;
      width: 8.5rem;
      height: 4.8rem;
      background-color: #000;
    }
  }
}

.list-wrapper {
  overflow: scroll;
  background: linear-gradient(to top,  #fdffeb, #d5f597);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100% 100%;

  ._back {
    height: 0.9rem;
    width: 1.8rem;
    position: fixed;
    top: 0.4rem;
    left: 0;
    z-index: 10;
  }
}

.bottom_image {
  bottom: 0;
}
</style>
